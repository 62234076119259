/* SuccessPage.css */
.box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  }
  
  .alert {
    width: 80%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .success-message {
    color: #28a745;
  }
  .warn-message {
    color: #b7861e;
  }
  .success-heading {
    font-size: 24px;
    margin-top: 10px;
  }
  
  .success-text {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .instruction-text {
    font-size: 14px;
    margin-top: 20px;
    color: #6c757d;
  }