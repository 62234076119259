.welcome-card {
  width: 400px;   /* Set card width */
  height: 300px;  /* Set card height */
  border-radius: 10px;
  background-color: #f8f9fa; /* Light background */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center text within the card */
}

.card-title {
  font-size: 1.8rem;
  color: #007bff;
}

.card-text {
  font-size: 1rem;
  color: #495057;
}

.btn-primary {
  font-size: 1.1rem;
}
